import React from "react"
import '../style.css'
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";


const ExternalButton = ({text,link,buttonStyles}) => {
  //Similar to download button, created for ease of reuse
  return (
    <a href={link} target='_blank'  rel="noreferrer" className={buttonStyles} style={{ display:'flex', justifyContent:'center'}}>
      <div>
          <p style={{color:'#facb65', textAlign:'center', marginBottom:'0'}}>{text} <FaExternalLinkAlt style={{width:'0.9em', margin:'5px'}}/></p>
      </div>
    </a>
  )
}

ExternalButton.defaultProps = {
  text:'Check it out here',
  link:'/',
  buttonStyles:'media-item-buttons'
}

export default ExternalButton