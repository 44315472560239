import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";
import ExternalButton from "../components/externalButton";

const Activities = () => {

    const activities=[
        {
            title:"Epo's Chronicles",
            content:'A comic strip journey through space to learn about our origins and the universe in which we live.',
            link:'https://epos.lbym.org/',
            image:'epos.png'
        },
        {
            title:"Astronomy From Home",
            content:'Use real astronomical data in your classroom!',
            link:'https://afh.sonoma.edu/',
            image:'AfHlogo2CR.png'
        },
        {
            title:"Gamma-ray Constellations",
            content:'An interactive sky map that displays gamma-ray constellations.',
            link:'https://fermi.gsfc.nasa.gov/science/constellations/',
            image:'gammaConstellations.png'
        },
        {
            title:"NASA's Fermi E-Book",
            content:'To commemorate a milestone anniversary for NASA’s Fermi spacecraft, the mission team has published an e-book called “Our High-Energy Universe: 15 Years with the Fermi Gamma-ray Space Telescope.”',
            link:'https://science.nasa.gov/missions/fermi/explore-the-universe-with-the-first-e-book-from-nasas-fermi/',
            image:'ferm15.png'
        },
    ]

    return (
    <Layout>
        <div>
            <h1>ONLINE ACTIVITIES</h1>
            <Star />
            <div className="media-items-container">
                {activities.map( (a,index) =>
                    <div className="item-container" key={index}>
                        <a href={a.link}><img alt='placeholder'src={`/${a.image}`} className='image-styles'/></a>
                        <h4>{a.title}</h4>
                        <p>{a.content}</p>
                        <ExternalButton link={a.link}/>
                    </div>    
                )}
            </div>
        </div>   
    </Layout>
    )
}

export default Activities